export const baseConfig = {
 "debug": true,
 "defaultRedirectUrl": "https://platform.dev.sw-machines.io",
 "privacyPolicy": {
  "baseUrl": "/assets/docs/",
  "documentUrls": {
   "de": "PRIVACY_POLICY_DE.md",
   "en": "PRIVACY_POLICY_EN.md"
  }
 },
 "aws": {
  "cognito": {
   "userPool": {
    "userPoolId": "eu-central-1_YeFhCw93w",
    "clientId": "79uva99dkh1ovftv1ckj48cblf"
   }
  }
 },
 "allowedBrowsers": [
  "chrome",
  "crios",
  "chromium",
  "edge",
  "safari",
  "firefox",
  "opera"
 ],
 "localstorage": {
  "token_key": "swcp-jwt"
 },
 "passwordCriteria": {
  "length": {
   "title": "min. 8 characters",
   "pattern": "(?=^.{8,255}$).*"
  },
  "upperCaseLetters": {
   "title": "min. 1 uppercase letter (A-Z)",
   "pattern": "(?=.*[A-Z]).*"
  },
  "lowerCaseLetters": {
   "title": "min. 1 lowercase letter (a-z)",
   "pattern": "(?=.*[a-z]).*"
  },
  "number": {
   "title": "min. 1 number (0-9)",
   "pattern": "(?=.*[0-9]).*"
  },
  "specialChar": {
   "title": "min. 1 special character",
   "pattern": "(?=.*[~!@#$%^&*()_+-.?,;:}{\\]|[=\\/,<>`'\"])"
  },
  "dontMatchOld": {
   "title": "must not match with old password",
   "pattern": "dontMatchOld"
  },
  "matchConfirmationPassword": {
   "title": "new password and re-typed password must match",
   "pattern": "matchConfirmationPassword"
  },
  "enterValidationCode": {
   "title": "Enter the validation code",
   "pattern": "none"
  }
 },
 "maintenanceAnnouncement": {
  "activated": false,
  "text": "Due to maintenance, the SW CloudPlatform will be unavailable on Wednesday, September 11th, from 10:00 (am) to 12:00 (pm) UTC+2."
 },
 "maintenanceMode": {
  "activated": false,
  "text": "The SW CloudPlatform is currently undergoing maintenance. Please try back at 12:00 (pm) UTC+2."
 }
};